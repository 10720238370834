import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import styled from "styled-components";
import Vimeo from "@u-wave/react-vimeo";

import Layout from "../components/Layout";
import VideoBackgroundImg from "../img/backgroundVideo.png";

const VideoContainer = styled.div`
  background-image: url(${VideoBackgroundImg});
  height: 514px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const StyledVimeo = styled(Vimeo)`
  padding-top: 16px;
`;

export const Title = styled.h2`
  font-size: 22px;
  font-weight: bold;
  line-height: 22px;
  margin: 10px 0 10px 0;
  border-bottom: 1px solid #333;
  padding-bottom: 10px;
  color: #333;
`;

export const Description = styled.p`
  margin: 0 0 16px;
  line-height: 1.5;
  font-size: 13px;
  color: #808080;
`;

export const VideoEntryTemplate = ({
  title,
  description,
  videoUrl,
  helmet
}) => {
  return (
    <div>
      {helmet || ""}
      <VideoContainer>
        <StyledVimeo video={videoUrl} width="850" height="482px" />
      </VideoContainer>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </div>
  );
};

VideoEntryTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  videoUrl: PropTypes.string,
  helmet: PropTypes.object
};

const VideoEntry = ({ data }) => {
  const { markdownRemark: entry } = data;

  return (
    <Layout>
      <VideoEntryTemplate
        description={entry.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${entry.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${entry.frontmatter.description}`}
            />
          </Helmet>
        }
        title={entry.frontmatter.title}
        videoUrl={entry.frontmatter.videoUrl}
      />
    </Layout>
  );
};

VideoEntry.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default VideoEntry;

export const pageQuery = graphql`
  query VideoEntryByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        description
        videoUrl
      }
    }
  }
`;
