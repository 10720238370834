import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import styled from "styled-components";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import VideoThumbnail from "../components/VideoThumbnail";
import {
  Title as VideoTitle,
  Description as VideoDescription
} from "./video-entry";

const ThumbnailWall = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  align-items: center;
  grid-gap: 22px 30px;
`;

export const CategoryEntryTemplate = ({
  title,
  description,
  videos,
  helmet
}) => {
  videos = videos || [];

  return (
    <section>
      {helmet || ""}

      <ThumbnailWall>
        {videos.map(video => (
          <VideoThumbnail key={video.id} video={video} />
        ))}
      </ThumbnailWall>
      <VideoTitle>{title}</VideoTitle>
      <VideoDescription>{description}</VideoDescription>
    </section>
  );
};

CategoryEntryTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  videos: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, videoUrl: PropTypes.string })
  ),
  helmet: PropTypes.object
};

const CategoryEntry = ({ data }) => {
  const { markdownRemark: entry } = data;

  return (
    <Layout>
      <CategoryEntryTemplate
        description={entry.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${entry.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${entry.frontmatter.description}`}
            />
          </Helmet>
        }
        title={entry.frontmatter.title}
        videos={entry.fields.videos}
      />
    </Layout>
  );
};

CategoryEntry.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default CategoryEntry;

export const pageQuery = graphql`
  query CategoryEntryByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      fields {
        videos {
          id
          fields {
            slug
            vimeoId
          }
        }
      }
      frontmatter {
        title
        description
        videos
      }
    }
  }
`;
