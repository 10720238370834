import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { Link } from "gatsby";

// TODO should come from env vars
const vimeoToken = "9105d2ceb3e1e40aa766377cd017e5c0";

const vimeoInstance = axios.create({
  baseURL: "https://api.vimeo.com",
  headers: { Authorization: `bearer ${vimeoToken}` }
});

const ThumbnailImg = styled.img`
  filter: drop-shadow(0px 0px 2px #000);
  border: solid white 1px;
  opacity: 0.94;
  transition: all 0.2s ease;

  :hover {
    opacity: 1;
  }
`;

const VideoThumbnail = ({ video }) => {
  const [thumbnails, setThumbnails] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await vimeoInstance.get(
        `/videos/${video.fields.vimeoId}/pictures`
      );

      setThumbnails(result.data.data);
    };

    fetchData();
  }, []);

  return (
    <Link to={video.fields.slug}>
      {thumbnails.length > 0 ? (
        <ThumbnailImg
          src={thumbnails[0].sizes[Math.min(3, thumbnails[0].sizes.length - 1)].link}
          width={410}
          height={228}
        />
      ) : (
        <div style={{ width: 410, height: 228, backgroundColor: "white" }} />
      )}
    </Link>
  );
};

export default VideoThumbnail;
